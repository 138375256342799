import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, Hidden, IconButton, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViknCheckboxGroup from "../../Components/Utils/ViknCheckboxGroup";
import VBTextField from "../../Components/Utils/VBTextField";
import VBInputField from "../../Components/Utils/VBInputField";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  borderRadius: 3,
  // p: 4,
};

export default function ViewInvoice({
  open,
  setOpen,
  SelectedInvoice,
  submitInvoice,
  apiResult,
  validate_xml,
  invoice_status,
  loadingView,
  resubmit,
  
}) {
  const [isActive, setisActive] = useState({
    isInvoice: false,
    isSandBox: false,
    isReSubmit:false
  })
  const [t] = useTranslation("common");
  
  console.log(isActive);
  
  const [inputs, setInputs] = useState('')

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    isActive.isInvoice && validate_xml(SelectedInvoice)
    isActive.isSandBox && submitInvoice(SelectedInvoice, "sandbox")
    isActive.isReSubmit && resubmit(SelectedInvoice,"sandbox")
  }

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "18px", fontWeight: "500", color: "#001746" }}
          >{invoice_status === 0 ?"Invoice View":"Submit Your Invoice"}
          </Typography>

          <IconButton onClick={() => handleClose()}>
            <CloseIcon sx={{ color: "#0A9EF3" }} />
          </IconButton>
        </Box>
        <Divider />

          <Box sx={{ p: 2,maxHeight:"85vh",overflow:"hidden" }}>
          {invoice_status === 0 &&
            <ViknCheckboxGroup
              isBorder={false}
              isNoWidth={true}
              // sx={flexd}
              radios={[
                {
                  label: "Validate Your Invoice",
                  checked: isActive.isInvoice,
                  onChange: () => {
                    setisActive({
                      isInvoice: !isActive.isInvoice,
                      isSandBox: false,
                      isReSubmit: false
                    })
                  },
                },
                {
                  label: "Submit Your Invoice",
                  checked: isActive.isSandBox,
                  onChange: () => {
                    
                    setisActive({
                      isInvoice: false,
                      isReSubmit: false,
                      isSandBox: !isActive.isSandBox
                    })
                  },
                },
              ]}
            />
          }
          
          {invoice_status === 2 &&
            <ViknCheckboxGroup
              isBorder={false}
              isNoWidth={true}
              // sx={flexd}
              radios={[
                {
                  label: "ReSubmit Your Invoice",
                  checked: isActive.isReSubmit,
                  onChange: () => {
                    setisActive({
                      isReSubmit: !isActive.isReSubmit,
                      isSandBox: false,
                      isInvoice: false,
                    })
                  },
                }
             
              ]}
            />
          }
          
          {(invoice_status === 0 || invoice_status === 2) &&
            <Button
              variant="contained"
              sx={{
                my: 2,
                textTransform: "none",
                backgroundColor: "#0A9EF3",
                py: "3px",
                ":hover": { backgroundColor: "#0A9EF3" },
              }}
              onClick={() => {
                handleSubmit()
              }}
            >
              {loadingView?"Loading...":"Submit"}
            </Button>
          }
          
          
          
          {/* <Paper
            sx={{
              backgroundColor: "#F8F8F8",
              border: "1px solid #D9D9D9",
              borderRadius: 2,
              p: 3,
              pb: 6,
              marginBottom:6
            }}
            elevation={0}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: 2 }}>
              Result
            </Typography>


            <div style={{ maxHeight: "calc(85vh - 220px)", overflow: "scroll", marginBottom: "20px" }}>
              <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', color: '#333' }}>
                {apiResult?JSON.stringify(apiResult, null, 2):"No Data"}
                
              </pre>
            </div>
          </Paper> */}
          <Paper
            sx={{
              backgroundColor: "#F8F8F8",
              border: "1px solid #D9D9D9",
              borderRadius: 2,
              p: 3,
              pb: 6,
              marginBottom: 6,
            }}
            elevation={0}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: 2 }}>
              {t("Result")}
            </Typography>

            <div style={{ maxHeight: "calc(85vh - 220px)", overflow: "scroll", marginBottom: "20px" }}>
              {apiResult ? (
                typeof apiResult === 'string' ? (
                  <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', color: '#333' }}>
                    {apiResult}
                  </pre>
                ) : (
                  <div style={{ maxHeight: "calc(85vh - 220px)", overflow: "scroll", marginBottom: "20px" }}>
                  <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', color: '#333' }}>
                    {apiResult?JSON.stringify(apiResult, null, 2):"No Data"}
                    
                  </pre>
                </div>
                )
              ) : (
                "No Data"
              )}
            </div>
          </Paper>

        </Box>
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {/* <li style={{ marginBottom: "10px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                color: "white",
                fontWeight: "bold",
                textTransform: "uppercase",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                width: "100%",
                padding: "10px",
                transition: "background-color 0.3s ease, transform 0.3s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "darkgreen")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "green")
              }
              // onClick={()=>{submitInvoice(SelectedInvoice,"validation")}}
              onClick={() => {
                validate_xml(SelectedInvoice);
              }}
            >
              Validate Your Invoice
            </Button>
          </li>
          <li style={{ marginBottom: "10px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "yellow",
                color: "black",
                fontWeight: "bold",
                textTransform: "uppercase",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                width: "100%",
                padding: "10px",
                transition: "background-color 0.3s ease, transform 0.3s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "orange")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "yellow")
              }
              onClick={() => {
                submitInvoice(SelectedInvoice, "sandbox");
              }}
            >
              Submit to sandbox
            </Button>
          </li> */}
          {/* <li style={{ marginBottom: '10px' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: 'indigo',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            width: '100%',
            padding: '10px',
            transition: 'background-color 0.3s ease, transform 0.3s ease'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkblue'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'indigo'}
        >
          Submit to simulation
        </Button>
      </li>
      <li>
        <Button
          variant="contained"
          style={{
            backgroundColor: 'brown',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            width: '100%',
            padding: '10px',
            transition: 'background-color 0.3s ease, transform 0.3s ease'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'darkred'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'brown'}
        >
          Submit to production
        </Button>
      </li> */}
        </ul>
        {/* <div
          style={{
            padding: "20px",
            maxWidth: "800px",
            margin: "20px auto",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2 style={{ color: "#333" }}>Response:</h2>
          <div
            style={{
              backgroundColor: "#f7f7f7",
              border: "1px solid #ddd",
              borderRadius: "4px",
              padding: "15px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            <pre
              style={{
                margin: 0,
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                color: "#333",
              }}
            >
              {JSON.stringify(apiResult, null, 2)}
            </pre>
          </div>
        </div> */}
      </Box>
    </Modal>
  );
}
