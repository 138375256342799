import { Box, Paper, styled, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ClearButton,
  ListButton,
  LoadingButton,
  SaveButton,
} from "../../Components/Utils/StyledButtons";
import { useTranslation } from "react-i18next";
import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
} from "../../Components/CommonComponents";
import MyGrid from "./Details";
import {
  CallSalesReturnDetail,
  CallsalesToSalesreturn,
  CallToSalesreturn,
  edit_salesreturn_api,
  get_salesreturn_details_api,
  LedgerDetails,
  view_salesreturn_api,
} from "../../Api/Sales/SalesReturnApis";
import { openSnackbar } from "../../features/SnackBarslice";
import {
  billwise_datas,
  get_pricelist_list_new_api,
  get_product_by_barcode_api,
  get_product_history_api,
  get_single_pricelist_new_api,
  get_stock_of_products_api,
  get_VoucherNo,
  ledger_details_api,
  place_of_supply_api,
  product_search_new_api,
} from "../../Api/CommonApi/CommonApis";
import NotesTextArea from "../../Components/Utils/TextArea";
import { SalesInvoiceCalc, SalesLineCalc, SalesOrderValidation, SalesReturnValidation } from "./functions";
import SummaryDetails from "./SummaryDetails";
import { debounce } from "../../Function/comonFunction";
import { CallEmployees } from "../../Api/Reports/CommonReportApi";
import { HistoryModal } from "../../Components/CommonForms";
import SideMenuBottom from "../../Components/Utils/BottomNavigationMenu";
import { CreateSalesOrder, edit_salesOrder_api, get_sales_order_details, SalesOrderDetails, view_salesorder_api } from "../../Api/Sales/SalesOrder";
import moment from "moment";

const SalesOrder = () => {
  const [t, i18n] = useTranslation("common");

  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  const is_edit = location.state?.is_edit ?? params.get('is_edit');
  const uniq_id = location.state?.uniq_id ?? params.get('unq_id');

  const dispatch = useDispatch();
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    EnableSalesManInSales,
    PurchasePriceInSales,
    EnableBillwise,
    show_productcode_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_stock_InsearchSale,
    PriceCategory
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  let user_role_settings = useSelector((state) => state.userRollSettings);
  let salesReturn_permission = user_role_settings.filter((i) => i.name === "Sales Return");
  let sales_discount_perm = salesReturn_permission[0].discount;
  let sales_discount_limit = salesReturn_permission[0].discount_limit? salesReturn_permission[0].discount_limit: 100;
  let sales_save = salesReturn_permission[0].save_permission;
  let sales_purchase_price_perm = salesReturn_permission[0].purchase_price;
  
  let sales_history_permission = user_role_settings.filter((i) => i.name === "Sales History");
  let purchase_history_permission = user_role_settings.filter((i) => i.name === "Purchase History");

  let sales_history_view_permission = sales_history_permission[0].view_permission
  let purchase_history_view_permission = purchase_history_permission[0].view_permission

  const navigate = useNavigate();
  const [IsButtonClick, setIsButtonClick] = useState(false);
  const { Country, State } = useSelector((state) => state.companyDetails);
  const [is_manual_roundoff,setIs_manual_roundoff] = useState(false)

  const [IsSalesHistory, setIsSalesHistory] = useState(false);
  const [IsPurchaseHistory, setIsPurchaseHistory] = useState(false);

  let GeneralSettingsData = {
    EnableSalesManInSales,
    PriceDecimalPoint,
    PurchasePriceInSales,
    EnableBillwise,
    show_productcode_InsearchSale,
    show_stock_InsearchSale,
    EnableProductBatchWise,
    sales_purchase_price_perm,
    show_purchasePrice_InsearchSale,
  }

  // let today = new Date();
  // today.setDate(today.getDate());
  // let DefaultDate = today.toISOString().substr(0, 10);
  let today = moment();
  let DefaultDate = today.format("YYYY-MM-DD");
  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular", is_gst: true },
    { value: "1", name: "Registered Business - Composition", is_gst: true },
    { value: "2", name: "Consumer", is_gst: true, default: true },
    { value: "4", name: "Overseas", is_gst: true },
    { value: "5", name: "Special Economic Zone", is_gst: true },
    { value: "6", name: "Deemed Export", is_gst: true },
    { value: "0", name: "Business to Business(B2B)", is_vat: true },
    {
      value: "1",
      name: "Business to Customer(B2C)",
      is_vat: true,
      default: true,
    },
  ];


  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "/list-sales-order",
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];



  const GST_TreatmentData = TreatmentData.filter((i) => i.is_gst === true);
  const VAT_TreatmentData = TreatmentData.filter((i) => i.is_vat === true);

  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData: [],
    CashList: [],
    BankList: [],
    CountryData: [],
    EmployeeData: [],
    TaxCategoryData: [],
    UserTableData: [],
    WarehouseData: [],
    PlaceOfSupplyData: [],
    ProductList: [],
    ProductCodeList: [],
    SalesHistory:[],
    PurchaseHistory:[],
    PriceCategoryData: [],
  });

  const [state, setState] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    Employee: null,
    Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    Date: DefaultDate,
    RefferenceBillNo: "",
    DeliveryDate: DefaultDate,
    BillDiscPercent: 0,
    BillDiscAmt: 0,
    CashAmount: 0,
    BankAmount: 0,
    Notes: "",
    //Total
    TotalQty: 0,
    TotalGrossAmt: 0,
    TotalDiscount: 0,
    TotalTax: 0,
    NetTotal: 0,
    TotalTaxableAmount: 0,
    TaxTaxableAmount: 0,
    NonTaxTaxableAmount: 0,
    GrandTotal: 0,
    LedgerValue: "",
    RoundOff:"0",
    PriceCategory:null,
    TotalSGST:0,
    TotalCGST:0,
    TotalIGST:0,
    TaxType:VAT ? "VAT" : "GST Intra-state B2C",
    TaxID:VAT ? 32 : 22,
  });

  //when you adding any field here add in AddLine and clearData function tooo
  let DataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Description: "",
    Unit: null,
    UnitList: [],
    Stock: "",
    Qty: "",
    FQty: "",
    Rate: "",
    AvgCost: "",
    Tax:null,
    InclusivePrice: "",
    GrossAmount: "",
    DiscountPerc: "",
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: "",
    MRP:"",
    PurchasePrice:"",
    MinimumSalesPrice:"",
    AverageCost:"",
    TaxableAmount:0,
    NonTaxableAmount:0,
    BatchCode:null,
    IGSTAmount : 0,
    IGSTPerc : 0,
    CGSTAmount : 0,
    CGSTPerc : 0,
    SGSTAmount : 0,
    SGSTPerc : 0,

  }
  const [DataList, setDataLists] = useState([DataListItem]);

  const [defaultValue, setDefaultValue] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    PriceCategory: null,
    // Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    // ShippingTax: null,
  });

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });

  const [openCustomVoucher, setopenCustomVoucher] = useState(false)
  const [OpenBillWise, setOpenBillWise] = useState(false)
  const [IsBillwise, setBillwise] = useState(false)
  const is_customer = state.AccountLedger?.AccountGroupUnder !== 9 && state.AccountLedger?.AccountGroupUnder !== 8;

  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: false,
    BarCode: false,
    Product: true,
    Description: false,
    Unit: true,
    Qty: true,
    FQty: false,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: false,
    DiscountAmount: false,
    TaxAmount: true,
    Amount: true,
    Activity: true,
    BatchCode:true,
    PurchasePrice:false,
    Stock:true,
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
    BatchCode:true,
    PurchasePrice:false,
    Stock:true,
  });

  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };
  

  const MasterCalcFields = [
    "BillDiscPercent",
    "BillDiscAmt",
    "CashAmount",
    "BankAmount",
    "RoundOff"
  ];

  const onMasterChange = (e) => {
    if(e){
      const { name, value } = e.target;
      
      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);
      
      if (isCalcField){
        state[name] = value
        
        
        //handling bill disc 
        if (name === "BillDiscAmt"){
          state.BillDiscPercent = 100 * (Number(value)/Number(state.NetTotal))
        }
        else if (name === "BillDiscPercent"){
          state.BillDiscAmt = (Number(value)/100) * Number(state.NetTotal)
        }
        
            
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff);
        setState({ ...newState });
      }
      else{
        setState((prevState) => ({ ...prevState, [name]: value }));
      } 
    }
    
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator?dataState.SuffixSeparator:"";
    let Suffix = dataState.Suffix?dataState.Suffix:"";
    let VoucherNo = String(PreFix) + String(Seperator) + String(InvoiceNo) +String(SuffixSeparator) +String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setopenCustomVoucher(false);
  }

  const onMasterInputChange = async (search, name) => {
    if (search && name) {
      let length = search.length;
      if (name === "AccountLedger") {
        const LedgerList = await LedgerDetails({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          ledger_name: search,
          length: length,
          load_data: false,
          type_invoice: "SalesInvoice",
        });
        console.log(LedgerList);
        if (LedgerList.StatusCode === 6000) {
          let AccountLedgerData = [...LedgerList.data];
          setDataState({ ...dataState, AccountLedgerData: AccountLedgerData });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: LedgerList?.message ?? "Api Error",
              severity: "error",
            })
          );
        }
      } else if (name === "Employee") {
        const EmployeeList = await Call_Employees_api({
          CompanyID : CompanyID,
          BranchID : BranchID,
          CreatedUserID : user_id,
          PriceRounding : Number(PriceDecimalPoint || 2),
          search : search,
          list_type : "sales",
        })

        const uniqueSalesmansData = EmployeeList.filter((salesman) => {
          // Check if the salesman id is not already in EmployeesList
          return !dataState.EmployeeData.some((employee) => employee.id === salesman.id);
        });
          let EmployeeData = [...dataState.EmployeeData,...uniqueSalesmansData]
          setDataState({...dataState,EmployeeData:EmployeeData})
      }
    }
  };

  const onMasterAutoChange = async(e, v, name) => {
    if(v && name){
      const isCalcField = MasterCalcFields.includes(name);
      let value = v
      if (name === "AccountLedger"){
        let data = await Call_LedgerDetials(value)
        let Treatment = VAT_TreatmentData.find((i)=> i.value === data.Treatment)
        
        let PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === 1)
        if (data.PriceCategoryID){
          PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === data.PriceCategoryID)
        }
        
        setState({...state,[name]:data,Treatment,ShippingAddress:null,BillingAddress:null,PriceCategory})
      }
            
      else if (name === "Warehouse"){
        let Data = DataList
        let payload = DataList.map(object => object?.Unit?.PriceListID);
        let StockData = await Call_Stock_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          PriceListIDList : payload ,
          WarehouseID:value.WarehouseID
        })
        
        Data.map((lineItem,index)=>{
          if (lineItem.Product){            
            lineItem.Stock =  StockData.find((i)=> i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0
            Data[index] = lineItem
          }
        }) 
        
        setDataLists([...Data]);
        setState({...state,[name]:value})
        
        
      }
      
      else if(isCalcField){
        state[name] = value
        let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff);
        setState({ ...newState });
      }
      else{
        setState({...state,[name]:value})
      }
    }  
  }

  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse?.WarehouseID,
          is_product_image: false,
          length: value?.length,
          product_name: value,
          type: "SalesOrder",
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };

  const onDetailsChange = async(e,index) =>{
    if (e){
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      console.log(name,value,"name,value:LLLLLLLLLLLLLLL:::::::::::::::::::::::::::");
      let lineItem = Data[index]
      lineItem[name] = value
    
      // handling Line Discount
      let Amount = lineItem.GrossAmount + lineItem.TaxAmount
      if (name === "DiscountAmount"){
        lineItem.DiscountPerc = 100 * (Number(value)/Number(Amount))
      } else if (name === "DiscountPerc"){
        lineItem.DiscountAmount = (Number(value)/100) * Number(Amount)
      }


      let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem)
      
      Data[index] = calculated_lineItem
      setDataLists([...DataList])
    }
  }

  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
        
      }
    }
    
    let newState = SalesInvoiceCalc(dataState,state, Data);
    setState({ ...newState,deletedData:deletedData });
    
    setDataLists([...Data]);
  };
  


  //Ledger Searching api
  const Call_LedgerDetials = async (data) => {
    const LedgerDetails = await ledger_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ledger_id: data?.id,
      PriceRounding: PriceDecimalPoint,
    });

    if (LedgerDetails.StatusCode === 6000) {
      console.log(LedgerDetails,"LLLLLLLLLLLLLEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE>>>>>>>>>>>>>>>>>>>.");
      let AccountLedger = data; //state.AccountLedger
      AccountLedger["Treatment"] = LedgerDetails.data.Treatment;
      AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo;
      AccountLedger["Mobile"] = LedgerDetails.data.Mobile;
      AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress;
      AccountLedger["RouteName"] = LedgerDetails.data.RouteName;
      AccountLedger["Balance"] = LedgerDetails.data.Balance;

      // setState({...state,AccountLedger:AccountLedger})
      return AccountLedger;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerDetails?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
  };



  


  const Call_Stock_api = async(payload) =>{
    let data = null
    const Response = await get_stock_of_products_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }


  // Product UnitList(PriceList) list api
  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    console.log(Response, "Response");
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  // Product Search API
  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };

  const onDetailsAutoChange = async (v, index, name) => {
    console.log(v, name, "333333333");
    if (v && name && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "SI",                     //______________----------------------------------------------------------------------------------------------------------------------------
        });

        let Unit = UnitList.find((i) => i.DefaultUnit === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse?.WarehouseID,
        });

        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscountPerc = 0;
        let DiscountAmount = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }

        if (EnableProductBatchWise === true){
          if (UnitDetails.BatchCode){
            lineItem.Rate = UnitDetails.BatchCode.SalesPrice
            lineItem.PurchasePrice = UnitDetails.BatchCode.PurchasePrice
            lineItem.Stock = lineItem.BatchCode.Stock
          }
        }

        let Tax = dataState.TaxCategoryData.find(
          (i) => i.TaxID === Product.ProductTaxID
        );

        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.Description = Product.Description;
        lineItem.Stock = Product.Stock;
        lineItem.Qty = Qty;
        lineItem.FQty = FQty;
        lineItem.Tax = Tax;
        lineItem.DiscountPerc = DiscountPerc;
        lineItem.DiscountAmount = DiscountAmount;

        lineItem.Unit = Unit;
        lineItem.UnitList = UnitList;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }
        lineItem.Unit = Unit;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }

      else if (name === "BatchCode"){
        let BatchCode = v
        lineItem.BatchCode = BatchCode
        lineItem.Rate = BatchCode.SalesPrice
        lineItem.PurchasePrice = BatchCode.PurchasePrice
        lineItem.Stock = BatchCode.Stock
        let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem)
        
        Data[index] = calculated_lineItem
        
        setDataLists([...Data]);
      }
    }
  };

  // initial APIS AND SETTING DATA ==========/

  const fetchData = async () => {
    let ResponseView = null
    let AccountLedgerData = [];
    let CashList = [];
    let BankList = [];
    let CountryData = [];
    let EmployeeData = [];
    let PriceCategoryData = [];
    let TaxCategoryData = [];
    let UserTableData = [];
    let WarehouseData = [];
    let Seperator = "";
    let Suffix = "";
    let SuffixSeparator = "";
    let PreFix = "";
    let InvoiceNo = "";
    let VoucherNo = "";
    let PlaceOfSupplyData = [];

    const Data = await SalesOrderDetails({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    });

    if (Data.StatusCode === 6000) {
      const PlaceOfSupplyResponse = await place_of_supply_api(Country);
      if (PlaceOfSupplyResponse.StatusCode === 6000) {
        PlaceOfSupplyData = PlaceOfSupplyResponse.data;
      }

      AccountLedgerData = Data.AccountLedgerData;
      let CashAndBankData = Data.CashAndBankData;
      if (CashAndBankData) {
        CashList = CashAndBankData.filter((i) => i.AccountGroupUnder === 9);
        BankList = CashAndBankData.filter((i) => i.AccountGroupUnder === 8);
      }

      CountryData = Data.CountryData;
      EmployeeData = Data.EmployeeData;
      PriceCategoryData = Data.PriceCategoryData;
      TaxCategoryData = Data.TaxCategoryData;
      UserTableData = Data.UserTableData;
      WarehouseData = Data.WarehouseData;

      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData;
      Seperator = VoucherNoGenerateTableData.Seperater;
      Suffix = VoucherNoGenerateTableData.Suffix;
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator;
      PreFix = VoucherNoGenerateTableData.PreFix;
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo;
      VoucherNo = VoucherNoGenerateTableData.VoucherNo;

      //Default Values
      let AccountLedger = AccountLedgerData.find((i) => i.is_default === true);
      let Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      let Treatment = VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
      let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      let PriceCategory = PriceCategoryData.find(
        (i) => (i.PriceCategoryID = 1)
      );
      let CashAccount = CashList[0];
      let BankAccount = BankList[0];
      // let ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")

      AccountLedger = AccountLedgerData.find((i) => i.is_default === true);
      Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      Treatment = VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
      PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      PriceCategory = PriceCategoryData.find((i) => (i.PriceCategoryID = 1));
      CashAccount = CashList[0];
      BankAccount = BankList[0];
      // ShippingTax = TaxCategoryData.find((i)=> i.TaxName === "None")

      setDefaultValue((prev) => {
        return {
          ...prev,
          AccountLedger: AccountLedger,
          CashAccount: CashAccount,
          BankAccount: BankAccount,
          PriceCategory: PriceCategory,
          Warehouse: Warehouse,
          Treatment: Treatment,
          PlaceOfSupply: PlaceOfSupply,
          // ShippingTax:ShippingTax,
        };
      });


      //calling single view api
      if (is_edit && uniq_id){
        ResponseView = await Call_SalesOrderMasterView({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id})
        console.log(ResponseView,"resssspppppppppppppoooooooooooo");
        if (ResponseView){
          AccountLedger = await Call_LedgerDetials(ResponseView.AccountLedger)
          Warehouse = ResponseView.Warehouse
          Treatment = ResponseView.Treatment
          PlaceOfSupply = ResponseView.PlaceOfSupply
          PriceCategory = ResponseView.PriceCategory
          CashAccount = ResponseView.CashAccount
          BankAccount = ResponseView.BankAccount


          VoucherNo = ResponseView.VoucherNo
          setIs_manual_roundoff(ResponseView.is_manual_roundoff)

          // Calling details in mutiple for reduce
          let TotalQty = Number(ResponseView.TotalQty)
          let noOfPages = Math.ceil(TotalQty / 100);

          let data = []
          for (let i=1;i<=noOfPages;i++){
            let DetailsReponse = await Call_salesOrder_details({BranchID:BranchID,CompanyID:CompanyID,"uniq_id":uniq_id,page:i})
            if (DetailsReponse){
              data = [...data,...DetailsReponse]
            }
          }

          setDataLists([...data])


        }
      }

      setState((prev)=>{
        return({...prev,
          ...ResponseView,
          AccountLedger,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          CashAccount,
          BankAccount,
          PriceCategory
        })      
      })

      setDataState((prev) => {
        return ({
          ...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
        });
      });
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "get salesOrder Api Error",
          severity: "error",
        })
      );
    }
  };


    //BarCode Api calling
    const Call_Barcode_api = async(payload) =>{
      let data = null
      const Response = await get_product_by_barcode_api(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = null
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }

    // search employee
    const Call_Employees_api = async(payload) =>{
      let data = []
      const Response = await CallEmployees(payload)
      console.log(Response,"Response");
      if (Response.StatusCode === 6000){
        data = Response.data
      }
      else if (Response.StatusCode === 6001){
        data = []
      }
      else{
        dispatch(
          openSnackbar({
            open: true,
            message: Response?.message ?? "Seles Man Search Api Error",
            severity: "error",
          })
        );
      }
      
      return data
    }
  

  
  // Change table Custamization
  const ChangeTableCustomization = (type,name) => {
    let value = null;
    if (type === "tableSettings"){
      value = !tableSettings[name];
      setTableSettings({
        ...tableSettings,
        [name]: value
      })
    } else {
      value = !focusSettings[name];
      setFocusSettings({
        ...focusSettings,
        [name]: value
      })
    }
  }  


  
  // Sales to Sales Return API on (From Sales)
  const CallFromSales = async (payload) => {
    let data = null;
    const response = await CallsalesToSalesreturn(payload)
    if (response.StatusCode === 6000) {
      data = response.data;
    }
    else if (response.StatusCode === 6001){
      data = null
      dispatch(
        openSnackbar({
          open: true,
          message : response?.message ?? "API error",
          severity: "warning",
        })
      )
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message : response?.message ?? "API error",
          severity: "error",
        })
      )
    }
    return data
  }

//  single View (Master)
  const Call_SalesOrderMasterView = async (payload) => {
    let data = []
    const Response = await view_salesorder_api(payload)
    if (Response.StatusCode === 6000) {
      data = Response.data
    } else if (Response.StatusCode === 6001) {
      data = []
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message : Response?.message ?? "An error occurred"
        })
      )
    }

    return data
  }
  
  // SalesOrder details view
  const Call_salesOrder_details = async(payload) => {
    console.log('JJJJJJJAASIMMMMMMM');
    
    let data = null
    const Response = await get_sales_order_details(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
    
  }

  
  const Call_Product_History_api = async(payload) =>{
    let data = []
    let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
    if (ProductIDs.length > 0){          
      let LastProduct =  ProductIDs[ProductIDs.length - 1].Product?.ProductID
      payload.CompanyID = CompanyID
      payload.BranchID = BranchID
      payload.CreatedUserID = user_id
      payload.LedgerID = state.AccountLedger.LedgerID
      payload.PriceRounding = 2
      payload.ProductIDs = [LastProduct]
    }
    
    const Response = await get_product_history_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.history_datas
    }
    else if (Response.StatusCode === 6001){
      data = []
      dispatch(
        openSnackbar({
          open: true,
          message: "No More Data!",
          severity: "warning",
        })
      );
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  



  const addByBarCode = async(text) =>{
    
    let Data = DataList;
    let index = Data.length
    let lineItem =  DataListItem
    console.log(lineItem,"lineItem--------------");
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
    })
    
    if (Product){
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id : Product.id,
        type_data : "SI"             // ------------------------------------------------------------------------------------------------------------------------
      })
      
      let Unit = UnitList.find((i) => i.DefaultUnit === true)
      let PriceListID = Unit.PriceListID
      

      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID:PriceListID,
        PriceRounding:Number(PriceDecimalPoint || 2),
        WarehouseID:state.Warehouse.WarehouseID
      })
      
      let Rate = 0 
      let Qty = 1
      let FQty = 0
      let DiscountPerc = 0
      let DiscountAmount = 0
      
  
      
      if (UnitDetails){
        Rate = Number(UnitDetails.Rate)
        
        lineItem.MRP = UnitDetails.MRP
        lineItem.Rate = Rate
        lineItem.PurchasePrice = UnitDetails.PurchasePrice
        lineItem.BarCode = UnitDetails.BarCode
        lineItem.BatchCode = UnitDetails.BatchCode
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice
        // lineItem.AverageCost = UnitDetails.AverageCost
  
      }
      
      let Tax = dataState.TaxCategoryData.find((i) => i.TaxID === Product.ProductTaxID)
  
      lineItem.Product = Product
      lineItem.ProductCode = Product
      lineItem.Description = Product.Description
      lineItem.Stock = Product.Stock
      lineItem.Qty = Qty
      lineItem.FQty = FQty
      lineItem.Tax = Tax
      lineItem.DiscountPerc = DiscountPerc
      lineItem.DiscountAmount = DiscountAmount
  
      lineItem.Unit = Unit
      lineItem.UnitList = UnitList
      
      let calculated_lineItem = await SalesLineCalc(dataState,state,lineItem)
      
      Data.push(calculated_lineItem)
      
      setDataLists([...Data]);
      
    }
    
  }

  const clearData = async () => {
    
    if (is_edit){
      navigate("/create-sales-order");
    }
    setIsButtonClick(false)
    
    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "SO",
    });
    
    setDataState({
      ...dataState,
      ...voucher_datas,
    })
  
    setState(({
      ...state,
      AccountLedger: defaultValue.AccountLedger,
      CashAccount: defaultValue.CashAccount,
      BankAccount: defaultValue.BankAccount,
      // Employee: null,
      Warehouse: defaultValue.Warehouse,
      Treatment: defaultValue.Treatment,
      PlaceOfSupply: defaultValue.PlaceOfSupply,
      Date: DefaultDate,
      RefferenceBillNo: "",
      DeliveryDate: DefaultDate,
      BillDiscPercent: 0,
      BillDiscAmt: 0,
      CashAmount: 0,
      BankAmount: 0,
      Notes: "",
      //Total
      TotalQty: 0,
      TotalGrossAmt: 0,
      TotalDiscount: 0,
      TotalTax: 0,
      NetTotal: 0,
      TotalTaxableAmount: 0,
      TaxTaxableAmount: 0,
      NonTaxTaxableAmount: 0,
      GrandTotal: 0,
      LedgerValue: "",
      RoundOff:"0",
    }))
    
    setDataLists([DataListItem,]);
    
  };
  

  const handleSubmit = async () => {
    setErrors({
      field : null,
      message : null,
      error : false,
      indexList : [], 
    })
    
    let { error, message, field, indexList } = await SalesOrderValidation(dataState,state,DataList,GeneralSettingsData);
    console.log(error, "errrrrrrrrrrrrrrrrrorrrorooroooooo");
    if (error){
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "warning",
        })
      );
      setErrors({ ...state, errors });
    } else {
      let Details = [];
      DataList.map((i) => {
        if (i.Product) {
          let item = {
            id : i.id,
            ProductID : i.Product.ProductID,
            Qty : i.Qty,
            UnitPrice : i.Rate,
            InclusivePrice : i.InclusivePrice,
            RateWithTax : i.Rate,
            Description : i.Description ??  '',
            DiscountAmount : i.DiscountAmount,
            GrossAmount : i.GrossAmount,
            TaxableAmount : i.TaxableAmount ,
            TaxAmount : i.TaxAmount,
            NetAmount : i.Amount,
            FreeQty : i.FQty,
            // CostPerPrice : i.Rate,
            PriceListID : i.Unit.PriceListID,
            DiscountPerc : i.DiscountPerc,
            // VATPerc : i.Rate,
            BatchCode : i?.BatchCode?.BatchCode,
            Description : i.Description,
            ProductTaxID : i.Tax.TaxID,
            NonTaxableAmount:i.NonTaxableAmount,
            PurchasePrice:i.PurchasePrice,
            SGSTAmount:i.SGSTAmount,
            CGSTAmount:i.CGSTAmount,
            IGSTAmount:i.IGSTAmount,
            IGSTPerc:i.IGSTPerc,
            CGSTPerc : i.CGSTPerc,
            SGSTPerc:i.SGSTPerc,
          }
          Details.push(item)
        }
      })

      let payload = {
        CompanyID : CompanyID,
        BranchID : BranchID,
        CreatedUserID : user_id,
        PriceRounding : PriceDecimalPoint,
        Details : Details,
        is_manual_roundoff : is_manual_roundoff,
        uniq_id:uniq_id,//uniq_id

        RefferenceBillNo : state.RefferenceBillNo,
        DeliveryDate : state.DeliveryDate,
        // ------
        VoucherDate : state.Date,
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        // ---------
        LedgerID : state.AccountLedger.LedgerID,
        PriceCategoryID:state.PriceCategory.PriceCategoryID,
        // SalesAccount : 86,
        EmployeeID :state.Employee?.EmployeeID,
        CustomerName:state.AccountLedger.LedgerName,
        // CashID:state.CashAccount.LedgerID,
        // BankID:state.BankAccount.LedgerID ?? 92,
        // WarehouseID:state.Warehouse.WarehouseID,
        Treatment:state.Treatment.value,
        State_of_Supply:state.PlaceOfSupply.id,
        Country_of_Supply:state.PlaceOfSupply.Country,
        BillDiscPercent:state.BillDiscPercent,
        BillDiscAmt:state.BillDiscAmt,
        CashAmount:state.CashAmount,
        BankAmount:state.BankAmount,
        Notes:state.Notes,
        RoundOff:state.RoundOff,
        // Total
        TotalQty:state.TotalQty,
        TotalGrossAmt : state.TotalGrossAmt,
        TotalDiscount:state.TotalDiscount,
        TotalTax:state.TotalTax,
        NetTotal:state.NetTotal,
        TotalTaxableAmount:state.TotalTaxableAmount,
        TaxTaxableAmount:state.TaxTaxableAmount,
        NonTaxTaxableAmount:state.NonTaxTaxableAmount,
        GrandTotal:state.GrandTotal,
        IsInvoiced : "N",
        TotalCGST:state.TotalCGST,
        TotalSGST:state.TotalSGST,
        TotalIGST:state.TotalIGST,
        TaxType : state.TaxType,
        TaxID : state.TaxID,
      }

      let responseData = null
      if (is_edit && uniq_id){
        setIsButtonClick (true);
        responseData = await edit_salesOrder_api(payload)
      }
      else{
        setIsButtonClick (true);
        responseData = await CreateSalesOrder(payload)
      }
      if (responseData?.StatusCode === 6000) {   
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        clearData();
      } else if (responseData?.StatusCode === 6001) {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
      } else if (responseData?.StatusCode === 6002) {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      } else {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
      
    }
  };



  //giving a little time waiting for onDetailsInputChange function
  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);


  //=============KEYBOARD SHORTCUTS===================== 
  const shortCutKeyPress = useCallback (async(event) => {
    //sales history
    if (event.altKey && (event.key === 'q' || event.keyCode === 81) && sales_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let SalesHistoryResponse = await Call_Product_History_api({
            Type:"Sales",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,SalesHistory:SalesHistoryResponse})
        }
      }
      setIsSalesHistory(true)
      setIsPurchaseHistory(false)
    }
    //purchase history
    else if (event.altKey && (event.key === 'w' || event.keyCode === 87 || event.keyCode === 119) && purchase_history_view_permission){
      if (DataList.length > 0){        
        let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
        if (ProductIDs.length > 0){              
          let PurchaseHistoryResponse = await Call_Product_History_api({
            Type:"Purchase",
            items_per_page:5,
            page_no:1
          })
          
          setDataState({...dataState,PurchaseHistory:PurchaseHistoryResponse})
        }
      }
      setIsPurchaseHistory(true)
      setIsSalesHistory(false)
    }
    //closing modal
    else{
      setIsSalesHistory(false)
      setIsPurchaseHistory(false)
    }
    
  },[state.AccountLedger,DataList])


  useEffect(() => {
    window.addEventListener('keydown', shortCutKeyPress);
    return () => {
      window.removeEventListener('keydown', shortCutKeyPress);
    };
  }, [shortCutKeyPress ]);

  console.log(state, "::::::>>>>>>>>>>>>>>>>>>stateeeeeeee",dataState);
  console.log(DataList,"DatatttttListttttttttttsttststtstt");
  console.log(state.AccountLedger?.LedgerID,"LedgerIDDDD");
  return (
    <>
      <Paper sx={{ height: "800px", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit ? t("Update Sales Order") : t("Create Sales Order")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            <ListButton
              onClick={() => {
                navigate("/list-sales-order");
              }}
              t={t}
            />

            {IsButtonClick ? (
              <LoadingButton t={t} />
            ) : (
              <SaveButton onClick={handleSubmit} t={t} />
            )}
          </FlexBox>
        </Box>

        {/* Select Customer Container */}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          <div class="sales-grid-item ">
            {/* customer search */}
            <InvoiceLedgerSearch name = {"AccountLedger"} label = {"Select Customer"} optionLabel = {"LedgerName"} optionLabel2 = {"LedgerCode"} OptionList = {dataState.AccountLedgerData} Value = {state.AccountLedger}  OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} is_customer={is_customer} />
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Warehouse"}
                  label={"Select a Warehouse"}
                  optionLabel={"WarehouseName"}
                  List={dataState.WarehouseData}
                  Value={state.Warehouse}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                VAT Treatment:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Treatment"}
                  label={"Select a Treatment"}
                  optionLabel={"name"}
                  List={VAT ? VAT_TreatmentData : GST ? GST_TreatmentData : []}
                  Value={state.Treatment}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PlaceOfSupply"}
                  label={"Select a Place Of Supply"}
                  optionLabel={"Name"}
                  List={dataState.PlaceOfSupplyData}
                  Value={state.PlaceOfSupply}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Employee"}
                  label={"Select a Employee"}
                  optionLabel={"FirstName"}
                  List={dataState.EmployeeData}
                  Value={state.Employee}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: PriceCategory?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Price Category:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"PriceCategory"} label = {"Select a Price Category"} optionLabel = {"PriceCategoryName"} List = {dataState.PriceCategoryData} Value = {state.PriceCategory} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  Value={dataState.VoucherNo}
                  openCustomVoucher={openCustomVoucher}
                  setCustomVoucher = {setopenCustomVoucher}
                  is_edit = {is_edit}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
            <CustomVoucher 
              open = {openCustomVoucher}
              setCustomVoucher = {setopenCustomVoucher}
              state={dataState}
              handleChange={handleChange}
              CustomVoucherSet = {CustomVoucherSet}
            />

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"Date"}
                  label={"Select a Date"}
                  Value={state.Date}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref Bill No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  name={"RefferenceBillNo"}
                  label={"Type a RefNo"}
                  Value={state.RefferenceBillNo}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Delivery Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"DeliveryDate"}
                  label={"Select a Date"}
                  Value={state.DeliveryDate}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
          </div>
        </Box>

        {/* Details Grid */}
        <Box
          sx={{
            // px: "26px",
            // py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            boxShadow:'none',
          }}
        >
          {/* ==========TABLE========== */}
          <MyGrid
            dataState={dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine={AddLine}
            RemoveLine = {RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            debouncedOnDetailsInputChange ={debouncedOnDetailsInputChange}
            ChangeTableCustomization = {ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}

          />
        </Box>

        {/* Detail Bottom Design ---------*/}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span
              style={{
                whiteSpace: "nowrap",
                color: "#0A9EF3",
                margin: 0,
                fontSize: "13px",
              }}
            >
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
              Add By Barcode :{" "}
            </span>
            <InvoiceBarCodeTextBox onEnter={addByBarCode} />
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div>
            {/* -------------------NOTES-------------*/}
            <br></br>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%):
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscPercent"}
                  label={"Type a BillDiscPercent"}
                  Value={state.BillDiscPercent}
                  OnChange={onMasterChange}
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscAmt"}
                  label={"Type a BillDiscAmt"}
                  Value={state.BillDiscAmt}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>

          {/* TOTAL AMOUNTS AND VALUES */}

          <SummaryDetails state={state} is_manual_roundoff={is_manual_roundoff} setIs_manual_roundoff={setIs_manual_roundoff} onChange={onMasterChange} Vat={VAT} Gst={GST}/>


                  {/* ============HISTORY MODALS============== */}
        </Box>
        <HistoryModal Type = {"Sales"} heading = {"Sales History"} List = {dataState.SalesHistory} open = {IsSalesHistory} setOpen = {setIsSalesHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState} />
        <HistoryModal Type = {"Purchase"}  heading = {"Purchase History"} List = {dataState.PurchaseHistory} open = {IsPurchaseHistory} setOpen = {setIsPurchaseHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState}  />
      </Paper>
      <SideMenuBottom data={MenuBottomData} />
    </>
  );
};

export default SalesOrder;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
